import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import regeneratorRuntime from 'regenerator-runtime';
import AccountOnboarding from './views/account/onboarding';
import AccountStatements from './views/account-statements';
import AddCompaniesToGroup from './views/add-companies-to-group';
import Company from './views/company';
import ConfirmIdentity from './views/confirm-identity';
import Contact from './views/account/contact';
import Disclosure from './views/account/disclosure';
import Employment from './views/account/employment';
import Identity from './views/account/identity';
import Income from './views/account/income';
import ForgotPassword from './views/forgot-password';
import Group from './views/group';
import GetStarted from './views/get-started';
import GroupBuy from './views/group-buy';
import GroupBuys from './views/group-buys';
import GroupInfo from './views/group-info';
import GroupSell from './views/group-sell';
import Investments from './views/investments';
import Invitations from './views/invitations';
import Invite from './views/invite';
import NewGroupBuy from './views/new-group-buy';
import NewGroupSell from './views/new-group-sell';
import NewTransfer from './views/new-transfer';
import NotFound from './views/not-found';
import Onboarding from './views/onboarding';
import Order from './views/order';
import Portfolio from './views/portfolio';
import PortfolioBuilder from './views/portfolio-builder';
import PortfolioReviews from './views/portfolio-reviews';
import Refer from './views/refer';
import ResetPassword from './views/reset-password';
import ScheduledBuy from './views/scheduled_buy';
import SignIn from './views/sign-in';
import Strategies from './views/strategies';
import Template from './views/template';

//utils
import { API, COOKIES, JWT, QUERY } from './utils';
import Appointments from './views/appointments';

function PublicRoute({ children, ...rest }) {
  const location = useLocation();

  useEffect(() => {
    if (process.env.ANALYTICS === '1') {
      analytics.page();
    }
  }, [location.pathname]);

  return (
    <Route {...rest} >
      {children}
    </Route>
  );
}

function PrivateRoute({ children, ...rest }) {
  const history = useHistory();
  const location = useLocation();
  const query = QUERY.toObject(location.search);
  const [authd, setAuthd] = useState(false);

  useEffect(() => {
    try {
      const idToken = query.idToken || COOKIES.get('id_token');
      if (!idToken) {
        throw new Error(`no idToken`);
      }

      const payload = JWT.parse(idToken);
      if (payload?.userId && payload.exp > Math.floor((new Date()).getTime() / 1000)) {
        setAuthd(true);
        COOKIES.set('user_id', payload.userId, COOKIES.ONE_YEAR);
        COOKIES.set('id_token', idToken, COOKIES.ONE_YEAR);
      } else {
        throw new Error(`invalid token`);
      }
    } catch (e) {
      COOKIES.remove('id_token');
      const queryString = location.pathname === '/' ? '' : `?next=${location.pathname}`;
      history.push(`/get-started${queryString}`); 
    }
  }, []);

  useEffect(() => {
    if (process.env.ANALYTICS === '1') {
      analytics.page();
    }
  }, [location.pathname]);

  return (
    <Route
      {...rest}
      render={() => authd ? children : null}
    />
  );
}

function Lazy(loader) {
  return (props) => {
    const [i, setI] = useState(null);
  
    useEffect(() => {
      (async () => {
       const i = await loader();
       setI(i);
      })();
    }, []);
  
    if (i) {
      const { default: Component } = i;
      return <Component {...props} />;
    }
  
    return null;
  }
}

const Activity = Lazy(() => import('./views/activity'));
const Admin = Lazy(() => import('./views/admin'));
const Chat = Lazy(() => import('./views/chat'));
const Compounding = Lazy(() => import('./views/calculators/compounding'));
const FAQ = Lazy(() => import('./views/faq'));
const Gift = Lazy(() => import('./views/gift'));
const Groups = Lazy(() => import('./views/groups'));
const JoinGroup = Lazy(() => import('./views/join-group'));
const JoinTemplateSMS = Lazy(() => import('./views/join-template-sms'));
const Liquidate = Lazy(() => import('./views/liquidate'));
const MyAccount = Lazy(() => import('./views/my-account'));
const NewGift = Lazy(() => import('./views/new-gift'));
const NewGroup = Lazy(() => import('./views/new-group'));
const NewOrder = Lazy(() => import('./views/new-order'));
const PortfolioBuilderV2 = Lazy(() => import('./views/calculators/portfolio-builder'));
const Profile = Lazy(() => import('./views/profile'));
const Search = Lazy(() => import('./views/search'));
const Settings = Lazy(() => import('./views/settings'));
const SettingsAdminGroup = Lazy(() => import('./views/settings/admin-group'));
const SettingsContact = Lazy(() => import('./views/settings/contact'));
const SettingsEmail = Lazy(() => import('./views/settings/email'));
const SettingsPassword = Lazy(() => import('./views/settings/password'));
const SettingsPhone = Lazy(() => import('./views/settings/phone'));
const Simulator = Lazy(() => import('./views/simulator'));
const TaxStatements = Lazy(() => import('./views/tax-statements'));
const PaymentMethod = Lazy(() => import('./views/new-payment-method'));

//WithClient renders the component and injects an API client
function WithClient({ render: Component }) {
  const client = API.getClient({
    idToken: COOKIES.get('id_token'),
  });
  return <Component client={client} />;
}

function App() {
  const isMobile = Boolean(COOKIES.get('app_version'));
  const client = API.getClient({
    idToken: COOKIES.get('id_token'),
  });

  const { location: { hostname } } = window;
  if (hostname.startsWith('simulator.')) {
    return <Router>
      <Switch>
        <Route exact path="/:code?">
          <WithClient render={Simulator} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  }

  if (hostname.startsWith('join.')) {
    return <Router>
      <Switch>
        <Route exact path="/:referralCode">
          <WithClient render={Refer} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  }

  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/get-started">
          <GetStarted />
        </PublicRoute>
        <PublicRoute exact path="/sign-in">
          <WithClient render={SignIn} />
        </PublicRoute>
        <PublicRoute exact path="/forgot-password">
          <WithClient render={ForgotPassword} />
        </PublicRoute>
        <PublicRoute exact path="/reset-password">
          <WithClient render={ResetPassword} />
        </PublicRoute>
        <PublicRoute exact path="/dollar-cost-averaging-calculator">
          <WithClient render={Simulator} />
        </PublicRoute>
        <PrivateRoute exact path="/">
          <WithClient render={Portfolio} />
        </PrivateRoute>
        <PrivateRoute exact path="/appointments">
          <Appointments client={client} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/onboarding/:section?/:step?">
          <AccountOnboarding client={client} isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/documents/:type">
          <WithClient render={ConfirmIdentity} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/contact">
          <WithClient render={Contact} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/disclosure">
          <WithClient render={Disclosure} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/employment">
          <WithClient render={Employment} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/identity">
          <WithClient render={Identity} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/income">
          <WithClient render={Income} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/statements">
          <WithClient render={AccountStatements} />
        </PrivateRoute>
        <PrivateRoute exact path="/account/tax-statements">
          <WithClient render={TaxStatements} />
        </PrivateRoute>
        <PrivateRoute exact path="/activity">
          <WithClient render={Activity} />
        </PrivateRoute>
        <Route path="/admin">
          <Admin client={client} />
        </Route>
        <Route path="/calculators/compounding">
          <Compounding client={client} />
        </Route>
        <Route path="/calculators/portfolio-builder">
          <PortfolioBuilderV2 client={client} />
        </Route>
        <PrivateRoute exact path="/companies/:ticker">
          <Company client={client} />
        </PrivateRoute>
        <PrivateRoute exact path="/chat">
          <WithClient render={Groups} />
        </PrivateRoute>
        <PrivateRoute exact path="/chat/:groupId">
          <Chat client={client} />
        </PrivateRoute>
        <PublicRoute exact path="/faq">
          <FAQ client={client} />
        </PublicRoute>
        <PrivateRoute exact path="/groups/new">
          <WithClient render={NewGroup} />
        </PrivateRoute>
        <PrivateRoute exact path="/groups/:groupId/add-companies">
          <WithClient render={AddCompaniesToGroup} />
        </PrivateRoute>
        <PrivateRoute exact path="/groups/:groupId/info">
          <WithClient render={GroupInfo} />
        </PrivateRoute>
        <PrivateRoute exact path="/groups/:groupId/:initialMode?">
          <Group client={client} isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/portfolio">
          <WithClient render={Portfolio} />
        </PrivateRoute>
        <PublicRoute exact path="/gifts/new">
          <NewGift client={client} />
        </PublicRoute>
        <PublicRoute exact path="/gifts/:id">
          <Gift client={client} />
        </PublicRoute>
        <PrivateRoute exact path="/group-buys">
          <WithClient render={GroupBuys} />
        </PrivateRoute>
        <PrivateRoute exact path="/group-buys/new/:groupId/:groupBuyId?">
          <WithClient render={NewGroupBuy} />
        </PrivateRoute>
        <PrivateRoute exact path="/group-sells/new/:groupId/:groupSellId?">
          <WithClient render={NewGroupSell} />
        </PrivateRoute>
        <PrivateRoute exact path="/history/group-buys/:groupBuyUserId">
          <WithClient render={GroupBuy} />
        </PrivateRoute>
        <PrivateRoute exact path="/history/group-sells/:groupSellUserId">
          <WithClient render={GroupSell} />
        </PrivateRoute>
        <PrivateRoute exact path="/history/orders/:id">
          <WithClient render={Order} />
        </PrivateRoute>
        <PrivateRoute exact path="/investments">
          <WithClient render={Investments} />
        </PrivateRoute>
        <PrivateRoute exact path="/invitations">
          <WithClient render={Invitations} />
        </PrivateRoute>
        <PrivateRoute exact path="/invitations/:invitationId/:mode?">
          <JoinGroup client={client} />
        </PrivateRoute>
        <PrivateRoute exact path="/invite/:groupId">
          <WithClient render={Invite} />
        </PrivateRoute>
        <PublicRoute exact path="/join/:code/sms">
          <JoinTemplateSMS client={client} />
        </PublicRoute>
        <PublicRoute exact path="/join/:code/:mode?">
          <WithClient render={JoinGroup} />
        </PublicRoute>
        <PrivateRoute exact path="/liquidate">
          <WithClient render={Liquidate} />
        </PrivateRoute>
        <PrivateRoute exact path="/my-account">
          <MyAccount client={client} />
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding/:groupId">
          <WithClient render={Onboarding} />
        </PrivateRoute>
        <PrivateRoute exact path="/orders/new/:side/:ticker/:orderId?">
          <WithClient render={NewOrder} />
        </PrivateRoute>
        <PrivateRoute exact path="/payment-method/:mode?">
          <PaymentMethod client={client} isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/history/portfolio-reviews">
          <WithClient render={PortfolioReviews} />
        </PrivateRoute>
        <PrivateRoute exact path="/profile/:id">
          <Profile client={client} />
        </PrivateRoute>
        <PrivateRoute exact path="/portfolio-builder">
          <PortfolioBuilder client={client} />
        </PrivateRoute>
        <PublicRoute exact path="/refer/:referralCode">
          <WithClient render={Refer} />
        </PublicRoute>
        <PrivateRoute exact path="/scheduled-buys/:id">
          <WithClient render={ScheduledBuy} />
        </PrivateRoute>
        <PrivateRoute exact path="/search">
          <Search client={client} />
        </PrivateRoute>
        <PrivateRoute path="/settings/accountInfo/contact">
          <WithClient render={SettingsContact} />  
        </PrivateRoute>
        <PrivateRoute path="/settings/accountInfo/email">
          <WithClient render={SettingsEmail} />  
        </PrivateRoute>
        <PrivateRoute path="/settings/accountInfo/password">
          <WithClient render={SettingsPassword} />  
        </PrivateRoute>
        <PrivateRoute path="/settings/accountInfo/phone">
          <WithClient render={SettingsPhone} />  
        </PrivateRoute>
        <PrivateRoute path="/settings/groups/:groupId/edit">
          <WithClient render={SettingsAdminGroup} />
        </PrivateRoute>
        <PrivateRoute path="/settings/:section?">
          <Settings client={client} />
        </PrivateRoute>
        <Route path="/simulator">
          <Simulator client={client} />
        </Route>
        <PublicRoute exact path="/stocks/:ticker">
          <Company client={client} />
        </PublicRoute>
        <PrivateRoute exact path="/strategies">
          <Strategies client={client} />
        </PrivateRoute>
        <PublicRoute path="/templates/:id">
          <WithClient render={Template} />  
        </PublicRoute>
        <PrivateRoute path="/transfers/new/:direction?">
          <WithClient render={NewTransfer} />  
        </PrivateRoute>
        <PublicRoute path="*">
          <NotFound />
        </PublicRoute>
      </Switch>
    </Router>
  );
}


if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN, 
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}

if (window.location.search.includes('v=')) {
  const { v } = QUERY.toObject(window.location.search);
  if (v) COOKIES.set('app_version', v);
}

ReactDOM.render(<App />, root);