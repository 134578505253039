import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Loading from './partials/loading';
import Nav from './partials/nav';
import PortfolioChart from './partials/portfolio-chart';
import Gifts from './partials/gifts';
import { BigNumber, BottomCard, Button, Radio, TinyButton } from './partials/ui';
import { MONEY, QUERY } from '../utils';
import arrowIcon from './assets/img/arrow-black.svg';
import calendarIcon from './assets/img/calendar.svg';
import fire from './assets/img/fire-outline.svg';
import infoIcon from './assets/img/info.svg';
import moneyBag from './assets/img/money-bag-outline.svg';
import pieChart from './assets/img/pie-chart-outline.svg';
import plus from './assets/img/plus.svg';
import warningIcon from './assets/img/warning.svg';
import useInfoCard from './hooks/info-card';

const displayPercent = (percent, digits = 2) => {
  const p = Math.abs(percent);
  const prefix = percent < 0 ? '-' : '';
  const min = d => Math.max(d, 0);
  if (percent === 0) return '0%';
  if (p < 0.001) return `${prefix}${p.toFixed(digits+3)}%`;
  if (p < 0.01) return `${prefix}${p.toFixed(digits+2)}%`;
  if (p < 0.1) return `${prefix}${p.toFixed(digits+1)}%`;
  if (p < 1) return `${prefix}${p.toFixed(digits)}%`;
  if (p < 10) return `${prefix}${p.toFixed(min(digits-1))}%`;
  if (p < 100) return `${prefix}${p.toFixed(min(digits-2))}%`;
  if (p < 1000) return `${prefix}${p.toFixed(min(digits-3))}%`;
  return `${prefix}${p.toFixed(min(digits-4))}%`;
}

const performanceColor = (number) => {
  return number < 0 ? 'orange' : 'cyan'
}

const durationText = (duration) => {
  switch (duration) {
    case '1w':
      return 'Past week';
    case '1m':
      return 'Past month';
    case '3m':
      return 'Past 3 months';
    case '6m':
      return 'Past 6 months';
    case '1y':
      return 'Past year';
    case 'all':
      return 'All time';
    default:
      return '';
  }
};

const parsePosition = (position) => ({
  ...position,
  dividendReturn: parseFloat(position.dividendReturn),
  dollarReturn: parseFloat(position.dollarReturn),
  marketValue: parseFloat(position.marketValue),
  percentReturn: parseFloat(position.percentReturn),
  quantity: parseFloat(position.quantity),
});

const sortPositions = (positions, sortBy) => {
  if (!positions) return [];
  const keys = {
    dollarReturn: ['dollarReturn', 'desc'],
    marketValue: ['marketValue', 'desc'],
    percentReturn: ['percentReturn', 'desc'],
    portfolioShare: ['diversity', 'desc'],
    quantity: ['quantity', 'desc'],
    ticker: ['ticker', 'asc'],
  };
  return _.orderBy(positions, keys[sortBy][0], keys[sortBy][1]);
};

export default function Portfolio({ client }) {
  const history = useHistory();
  const { isNew } = QUERY.toObject(useLocation().search);
  const [actionRequired, setActionRequired] = useState(null);
  const [invitations, setInvitations] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [gift, setGift] = useState(null);
  const [groups, setGroups] = useState(null);
  const [unvotedProposals, setUnvotedProposals] = useState(null);
  const [user, setUser] = useState(null);
  const [duration, setDuration] = useState(window.localStorage.getItem('duration') || '1m');
  const [menuOpen, setMenuOpen] = useState(false);
  const [info, setInfo] = useState(null);
  const [isSelectingChart, setIsSelectingChart] = useState(false);
  const [isShowingGifts, setIsShowingGifts] = useState(false);
  const [display, setDisplay] = useState(window.localStorage.getItem('display') || 'marketValue');
  const [sortBy, setSortBy] = useState(window.localStorage.getItem('sortBy') || 'ticker');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const [StrategiesInfo, StrategiesInfoButton] = useInfoCard('About Strategies');

  useEffect(() => {
    (async () => {
      setUser(await client.users.getMe());

      const [
        groups,
        invitations,
      ] = await Promise.all([
        client.groups.getMine(),
        client.invitations.get({ direction: 'incoming' }),
      ]);

      setGroups(groups);
      setInvitations(invitations);
    })();
  }, []);

  useEffect(() => {
    if (!isNew) return;
    (async () => {
      try {
      const [gift] = await client.gifts.get({ status: 'sent' });
        setGift(gift);
      } catch {}
    })();
  }, [isNew]);

  useEffect(() => {
    if (!user) return;

    (async () => {
      const proposals = await client.proposals.get({ isLive: true });
      const needsVote = proposals.filter(p => p.votes.findIndex(v => v.userId === user.id) === -1);
      setUnvotedProposals(needsVote);
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const portfolio = await client.portfolios.get({ duration });
        setPortfolio({
          ...portfolio,
          positions: portfolio.positions?.map(parsePosition),
        });
        window.localStorage.setItem('duration', duration);
      } catch (e) {
        setError(e.message);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [duration]);

  useEffect(() => {
    window.localStorage.setItem('sortBy', sortBy);
    window.localStorage.setItem('display', display);
  }, [display, sortBy]);

  const RightButton = useCallback(() => {
    return null;
  }, []);

  if (error) return <div className="absolute absolute--fill">
    <Nav title="Portfolio" />
    <div className="mt4 tc">
      An error occurred: {error}
    </div>
  </div>
;
  if (!user || !portfolio) return <Loading />;

  const sortedPositions = sortPositions(portfolio.positions, sortBy);

  const isBottomCardOpen = menuOpen || isSelectingChart;
  return <div className={`w-100 min-vh-100 mt6-ns overflow-x-hidden ${isBottomCardOpen ? 'vh-100' : ''}`}>
    <Nav client={client} title="Portfolio" rightButton={RightButton} />
    <div className="mh">
      {Boolean(actionRequired) && <div className="center bg-white ba b--light-gray br4 pa3 mt3" style={{maxWidth: 500}}>
        <div className="flex flex-row items-center justify-start">
          <div style={{flexShrink: 0}} className="mr3">
            <img src={infoIcon} style={{width: 20, height: 20}} />
          </div>
          <div onClick={actionRequired.url ? () => history.push(actionRequired.url) : _.noop}>
            {Boolean(actionRequired.title) && <div className="mb1 f5 bold">{actionRequired.title}</div>}
            <div className="f6 lh-copy">{actionRequired.description}</div>
          </div>
        </div>
      </div>}

      {unvotedProposals?.length > 0 && <div className="bg-white static-ns fixed shadow-4 mh pa3 br4 top-2 left-0 center-ns" style={{maxWidth: 500}}>
        <div className="flex flex-row items-center justify">
          <div style={{flexShrink: 0}} className="mr3">
            <img src={infoIcon} style={{width: 20, height: 20}} />
          </div>
          <Link to={`/groups/${unvotedProposals[0].groupId}`} className="black no-underline">
            <div className="mb1 f5 bold">New proposal!</div>
            <div className="f6 lh-copy">A proposal in {groups?.find(g => g.id === unvotedProposals[0].groupId)?.name} needs your vote. Vote now.</div>
          </Link>
        </div> 
      </div>}

      <div className={`bg-${performanceColor(portfolio?.percentReturn)} br5 tl center pv4 mt mb1 ${isFetching ? 'o-50' : ''}`} style={{maxWidth: 500}}>
        <div className="ph center">
          <PortfolioPerformance portfolio={portfolio} duration={duration} onChangeDuration={setDuration} />
        </div>
      </div>
      <div className="center" style={{maxWidth: 500}}>
        {Boolean(user.brokerageAccountId) && <InvestmentTiming client={client} />}
      </div>
      <div className={`tl center w-100-ns mt4`} style={{maxWidth: 500, paddingBottom: 80}}>
        {invitations?.length > 0 && <>
          <div className="f4 bold mb">My Invitations</div>
          {invitations.map((i) =>
            <Invitation key={`invitation-${i.id}`} invitation={i} />)}
        </>}

        {groups?.length > 0 && <div className="blue f6 pointer" onClick={() => setMenuOpen(true)} >Display options</div>}
        <div className="mt4">
          <div className="flex flex-row items-center justify-between" style={{marginBottom: 24}}>
            <div className="flex items-center">
              <div className="f4 bold mr2">My Portfolio</div>
              <StrategiesInfoButton />
            </div>
            {groups?.length > 0 && <img src={plus} onClick={() => history.push(`/groups/new`)} />}
          </div>
          {groups?.length > 0 && <div>
            {groups.sort((a, b) => Number(a.membership.isPaused) - Number(b.membership.isPaused)).map((g) =>
              <Group
                key={g.uuid}
                client={client}
                display={display}
                duration={duration}
                group={g}
                onChangeDuration={setDuration}
                proposals={unvotedProposals?.filter(p => p.groupId === g.id)}
                setInfo={setInfo}
                sortBy={sortBy}
                user={user}
              />)}
          </div>}
          {groups?.length === 0 && <div className="tc pa4 mb4 bg-gold br5 mt">
            <div className="f5 mb3">You don't have any investments yet.</div>
            <TinyButton onClick={() => history.push(`/groups/new`)}>
              <div className="flex flex-row items-center justify-center">
                <div>Create My First Investment</div>
                <img src={arrowIcon} className="ml2" style={{width: 16}} />
              </div>
            </TinyButton>

            <div className="mv3 underline">or</div>

            <TinyButton onClick={() => history.push(`/strategies`)}>
              <div className="flex flex-row items-center justify-center">
                <div>explore strategies</div>
                <img src={arrowIcon} className="ml2" style={{width: 16}} />
              </div>
            </TinyButton>
          </div>}
        </div>
        {sortedPositions.length > 0 && <div className="mt4">
          <div className="f4 bold mb">My Positions</div>
          <div className={isFetching ? 'o-50' : ''}>
            {sortedPositions.map((position, idx) =>
              <Position position={position} key={`position-${position.id}`} display={display} isLast={idx+1 === sortedPositions.length} />)}
          </div>
        </div>}
        <Watchlist client={client} />
      </div>
    </div>
    {info && <BottomCard dim onDismiss={() => setInfo(null)}>
      <div>
        <div className="f4 bold mb2">{info.title}</div>
        <div className="f6 lh-copy">{info.description}</div>
        <div className="mt">
          <Button width="w-100" onClick={() => setInfo(null)}>ok</Button>
        </div>
      </div>
    </BottomCard>}
    {menuOpen && <BottomCard dim onDismiss={() => setMenuOpen(false)}>
      <div className="center" style={{maxWidth: 500}}>
        <div className="w-100 tc bold f4">Display options</div>
        <div className="bold pv3 mt3">Display</div>
        <div className="pv2">
          <Radio name="display" value="marketValue" label="Your equity" defaultChecked={display === 'marketValue'} onChange={() => setDisplay('marketValue')} />
        </div>
        <div className="pv2">
          <Radio name="display" value="percentReturn" label="Percent return" defaultChecked={display === 'percentReturn'} onChange={() => setDisplay('percentReturn')} />
        </div>
        <div className="pv2">
          <Radio name="display" value="dollarReturn" label="Dollar return" defaultChecked={display === 'dollarReturn'} onChange={() => setDisplay('dollarReturn')} />
        </div>
        <div className="bold pv3 mt3">Sort by</div>
        <div className="pv2">
          <Radio name="sortBy" value="ticker" label="Symbol" defaultChecked={sortBy === 'ticker'} onChange={() => setSortBy('ticker')} />
        </div>
        <div className="pv2">
          <Radio name="sortBy" value="marketValue" label="Your equity" defaultChecked={sortBy === 'marketValue'} onChange={() => setSortBy('marketValue')} />
        </div>
        <div className="pv2">
          <Radio name="sortBy" value="percentReturn" label="Percent return" defaultChecked={sortBy === 'percentReturn'} onChange={() => setSortBy('percentReturn')} />
        </div>
        <div className="pv2">
          <Radio name="sortBy" value="dollarReturn" label="Dollar return" defaultChecked={sortBy === 'dollarReturn'} onChange={() => setSortBy('dollarReturn')} />
        </div>
        <div className="pv2">
          <Radio name="sortBy" value="portfolioShare" label="Portfolio share" defaultChecked={sortBy === 'portfolioShare'} onChange={() => setSortBy('portfolioShare')} />
        </div>
        <div className="mb2 mt4">
          <Button width="w-100" onClick={() => setMenuOpen(false)}>Done</Button>
        </div>
      </div>
    </BottomCard>}
    {!menuOpen && isSelectingChart && <BottomCard dim onDismiss={() => setIsSelectingChart(false)}>
      <div>
        <div className="w-100 tc bold mv3">Choose a Strategy</div>
        <div className="pv3 bb b--light-gray" onClick={() => onPickGroup(null)}>
          <div className="f5">All Strategies</div>
        </div>
        {groups.map((g, idx) => <div key={g.id} className={`pv3 ${idx < groups.length - 1 ? 'bb' : 'bn'} b--light-gray`} onClick={() => onPickGroup(g.id)}>
          <div className="f5">{g.name}</div>
        </div>)}
      </div>
    </BottomCard>}
    {Boolean(isShowingGifts) && <BottomCard dim>
      <Gifts client={client} user={user} onDismiss={() => setIsShowingGifts(false)} />
    </BottomCard>}
    {Boolean(gift) && <BottomCard backgroundColor={gift.backgroundColor} dim onDismiss={() => setGift(null)}>
      <div className="tc mw6 center" style={{backgroundColor: gift.backgroundColor}}>
        <div className="f3">{String.fromCodePoint(gift.emoji)}</div>
        <div className="mt3 f5 bold lh-copy">
          {gift.fromName} has given you {MONEY.formattedDollars((gift.amount + gift.matchAmount) / 100, { wholeDollar: true })} to start investing!
        </div>
        {Boolean(gift.message) && <div className="mt3">
          &quot;{gift.message}&quot;  
        </div>}
        {!user.brokerageAccountId && <div className="f6 mt3 lh-copy">
          The funds will be automatically deposited into your Share account and available to invest once you complete your account setup. 
        </div>}
        {Boolean(user.brokerageAccountId) && <div className="f6 mt3 lh-copy">
          The funds will be automatically deposited into your Share account and available to invest when your account is approved.
        </div>}

        {Boolean(gift.template) && <div className="f6 mt3 lh-copy">
          {gift.fromName} suggested the <span className="bold">{gift.template.name}</span> strategy for you. You can invest in it, or choose something else.
          <div className="mt3">
            <TinyButton onClick={() => history.push(`/templates/${gift.template.uuid}`)}>view {gift.template.name}</TinyButton>
          </div>
        </div>}
      </div>
    </BottomCard>}
    <StrategiesInfo>
      <div>
        <div className="bold purple">What's a strategy?</div>
        <p>A strategy is a collection of one or more stocks, like a personal ETF or a themed mini portfolio.</p>
        <div className="bold purple">Who makes them?</div>
        <p>Share offers premade strategies designed and maintained by our team of financial advisors. You can also start from scratch and build your own.</p>
        <div className="bold purple">How often are they updated?</div>
        <p>We review each of our strategies at least once per quarter. If you invest in one of our strategies, you will be automatically notified when we make changes and have the option to apply those changes to your own portfolio.</p>
        <div className="bold purple">How do I invest?</div>
        <p>All strategies use recurring weekly investments in an amount you control. You can also make one-time investments at any time.</p>
        <p>Have more questions? Check out our <Link to="/faq">FAQ.</Link></p>
      </div>
    </StrategiesInfo>
  </div>;
}

export function Position({ display, isLast, position, useColor = true }) {
  let text;
  const isUp = position.percentReturn >= 0;
  const prefix = isUp ? '+' : '';
  if (display === 'marketValue') {
    text = MONEY.formattedDollars(position.marketValue);
  } else if (display === 'percentReturn') {
    text = `${prefix}${(position.percentReturn * 100).toFixed(1)}%`;
  } else if (display === 'dollarReturn') {
    text = `${prefix}${MONEY.formattedDollars(position.dollarReturn)}`;
  } else if (display === 'lastPrice') {
    text = `${MONEY.formattedDollars(position.quote.latestPrice)}`;
  } else {
    text = `${MONEY.formattedDollars(position.marketValue)}`;
  }

  const color = (() => {
    if (!useColor) return 'bg-white';
    return isUp ? 'bg-cyan' : 'bg-orange';
  })();

  return <div style={{paddingBottom: isLast ? 0 : 24}}>
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row items-center justify-start">
        <div>
          <div className="f7 mb2">{displayPercent(position.diversity * 100, 1)} weight</div>
          <Link className="no-underline near-black f5 bold" to={`/companies/${position.ticker}`}>{position.ticker}</Link>
        </div>
      </div>
      <div className="tr">
        <div className={`${color} pa2 f6 mono br3`}>{text}</div>
      </div>
    </div>
  </div>;
}

function PortfolioPerformance({ portfolio, duration, onChangeDuration }) {
  if (!portfolio) {
    return <div className="w-100 tc flex flex-column">
    <BigNumber>$-.--</BigNumber>
    <div className={`bg-light-gray pa2 mt1 f5-ns f6 br2 center`}>-.-% All time</div>
  </div>;
  }

  const [mode, setMode] = useState('percent');
  const isUp = portfolio.dollarReturn >= 0;
  let bgColor;
  if (portfolio.costBasis === '0') {
    bgColor = 'cyan';
  } else if (isUp) {
    bgColor = 'cyan'
  } else {
    bgColor = 'orange';
  }
  
  const performanceText = (() => {
    const performance = mode === 'percent' ? `${(portfolio.percentReturn * 100).toFixed(1)}%` : MONEY.formattedDollars(parseFloat(portfolio.dollarReturn));
    return `${isUp ? '+' : ''}${performance}`;
  })();

  return <div className="w-100 tc flex flex-column pointer" onClick={() => setMode(mode === 'percent' ? 'dollars' : 'percent')}>
    <BigNumber>
      {MONEY.formattedDollars(parseFloat(portfolio.marketValue))}
    </BigNumber>
    <div className={`bg-${bgColor} mt1 f5 br2 center`}>{performanceText} {durationText(portfolio.duration)}</div>
    {portfolio.history.length > 1 && 
      <PortfolioChart
        backgroundColor={bgColor}
        chart={portfolio.history}
        duration={duration}
        durations={['1w', '1m', '3m', '1y', 'all']}
        labelKey={mode === 'percent' ? 'percentReturn' : 'dollarReturn'}
        onChangeDuration={onChangeDuration}
        valueKey="percentReturn"
      />}
  </div>;
}

function PortfolioReviewCard({ portfolioReview, onClick }) {
  return <div key={`portfolio-review-${portfolioReview.id}`} className="pa3 bg-white ba b--light-gray br5 w-100 mb2">
    {!portfolioReview.isSeen && <div className="flex justify-center mb2">
      <div className="bg-pink white pa1 ph2 br2 f7">New!</div>
    </div>}
    <div className="f4 tc bold">{portfolioReview.title}</div>
    <div className="flex justify-center mt2">
      <div className="f6">Portfolio Review</div>
    </div>
    <div className="bt b--light-gray mt3 pt3 tc ttu bold f7 blue" onClick={() => onClick(portfolioReview)}>
      Open
    </div>
  </div>;
}

function Group({ client, display, duration, group, onChangeDuration, proposals, setInfo, sortBy, user }) {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [portfolio, setPortfolio] = useState(null);

  useEffect(() => {
    if (portfolio?.duration === duration) return;
    (async () => {
      setIsFetching(true);
      const portfolio = await client.portfolios.get({ duration, groupId: group.id });
      setPortfolio({
        ...portfolio,
        positions: portfolio.positions.map(parsePosition),
      });
      setIsFetching(false);
    })();
  }, [duration]);

  const changeDuration = async (duration) => {
    setIsFetching(true);
    const portfolio = await client.portfolios.get({ duration, groupId: group.id });
    setPortfolio({
      ...portfolio,
      positions: portfolio.positions.map(parsePosition),
    });
    setIsFetching(false); 
    onChangeDuration(duration);
  }

  const goToGroup = (e) => {
    if (e.target.tagName === 'A') return;
    history.push(`/groups/${group.id}`);
  }

  const text = (() => {
    if (!portfolio) return '';
    switch (display) {
      case 'marketValue':
      case 'dollarReturn':
        return `${portfolio.percentReturn > 0 ? '+' : ''}${MONEY.formattedDollars(parseFloat(portfolio.dollarReturn))}`;
      case 'percentReturn':
        return `${portfolio.percentReturn > 0 ? '+' : ''}${(portfolio.percentReturn * 100).toFixed(1)}%`;
      default:
        return '';
    }
  })();

  const isEmpty = portfolio?.costBasis === '0';
  const performanceColor = (number) => {
    if (group.isNew) return 'bright-mint';
    if (isEmpty) return 'blue-gray';
    return number >= 0 ? 'cyan' : 'orange';
  }

  const openDiversity = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (portfolio) {
      setInfo({
        title: 'Percent of Portfolio',
        description: `${displayPercent(portfolio.diversity * 100)} of your portfolio is invested in ${group.name}.`,
      });
    } else {
      setInfo({
        title: 'Percent of Portfolio',
        description: 'The percentage of your portfolio that is invested in this strategy.',
      })
    }
  }

  const openStreak = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setInfo({
      title: 'Number of Investments',
      description: `You have invested in ${group.name} ${group.membership.investingStreak} times.`,
    });
  }

  const openDividendsReceived = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setInfo({
      title: 'Dividends Received',
      description: `The total amount of dividend payments you've received from the stocks in ${group.name}.`,
    });
  }

  const toggleCardIsOpen = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    window.setTimeout(() => {
      document.getElementById(group.uuid)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  }

  return <div id={group.uuid} className={`relative bg-${performanceColor(portfolio?.percentReturn)} br5 ${isEmpty ? 'ba b--light-gray' : ''} mb2 ${isFetching ? 'o-50' : ''}`} style={{transition: `max-height 0.5s ease-out`}} onClick={goToGroup}>
    <ContributionBadge groupUser={group.membership} />
    <div style={{padding: 24, paddingTop: 32, paddingBottom: 32}}>
      <div className="tc">
        <div className="bold f5">{group.name}</div>

        {!group.isNew && portfolio?.costBasis === '0' && group.membership.nextBuyAt && <div className="bg-blue-gray mt3 mb2">
          <div className="flex flex-row items-center justify-center">
            <img src={calendarIcon} className="mr2" style={{width: 18}} />
            <div className="f6 black tl lh-copy">
              Next buy day: {moment.unix(group.membership.nextBuyAt).format('MMMM D')}
            </div>
          </div>
        </div>}

        {portfolio && <div className="mt">
          <div className="mt1 bold" style={{fontSize: 42}}>
            {portfolio ? MONEY.formattedDollars(parseFloat(portfolio.marketValue)) : `$-.--`}
          </div>
          {!group.isNew && portfolio && <div className="flex items-center justify-center mt1">
            <div className={`bg-${performanceColor(portfolio.percentReturn)} f6 flex flex-row items-center`}>
              {text} {display === 'marketValue' ? '' : durationText(duration)}
            </div>
          </div>}

          <div className="flex flex-row items-center justify-center mt mono">
            <button className="flex flex-row items-center pointer black" onClick={openDiversity}>
              <img src={pieChart} style={{width: 24}} />
              <div className="f5">{displayPercent(portfolio?.diversity * 100, 1)}</div>
            </button>

            <div className="mh3">{String.fromCharCode(183)}</div>

            <button className="flex flex-row items-center pointerm black" onClick={openStreak}>
              <img src={fire} style={{width: 24}} />
              <div className="f5 ml1">{group.membership.investingStreak}</div>
            </button>

            <div className="mh3">{String.fromCharCode(183)}</div>

            <button className="flex flex-row items-center pointer black" onClick={openDividendsReceived}>
              <img src={moneyBag} style={{width: 24}} />
              <div className="f5 ml1">{MONEY.formattedDollars(group.membership.dividendsReceived, { wholeDollar: false })}</div>
            </button>
          </div>

          {isOpen && portfolio?.costBasis > 0 &&
            <PortfolioChart
              backgroundColor={performanceColor(portfolio.percentReturn)}
              chart={portfolio.history}
              duration={duration}
              durations={['1w', '1m', '3m', '1y', 'all']}
              labelKey={display}
              valueKey="percentReturn"
              onChangeDuration={changeDuration}
            />}
        </div>}

        {isOpen && proposals?.length > 0 && <div className="mt4">
          <TinyButton>
            <div className="flex flex-row items-center justify-start">
              <div className="mb1">{proposals.length} Proposal{proposals?.length > 1 ? 's' : ''}</div>
              <img src={arrowIcon} className="ml2" style={{width: 16}} />
            </div>
          </TinyButton>
        </div>}

        {group.isNew && <div className="tc mt3 bt b--black pt">
          <TinyButton bubbles={false} onClick={() => history.push(`/groups/${group.id}/add-companies`)}>
            <div className="flex flex-row items-center justify-center">
              Add Stocks <img src={arrowIcon} className="ml2" style={{width: 16}} />
            </div>
          </TinyButton>
        </div>}
      </div>

      {isOpen && portfolio?.positions?.length > 0 && <div className="mt pt3" onClick={e => e.stopPropagation()}>
        <div className="overflow-hidden" style={{transition: `max-height 0.5s ease-out`,}}>
          {sortPositions(portfolio.positions, sortBy).map((position, idx) =>
            <Position position={position} key={`position-${position.id}`} display={display} useColor={false} />)}
        </div>
      </div>}

      {portfolio?.positions?.length > 0 && <div className="flex flex-row items-center justify-center mt3" onClick={toggleCardIsOpen}>
        <div className="bg-black w-50" style={{height: 1, flexShrink: 1}}></div>
        <div className="f6 mh2 pointer" style={{flexShrink: 0}}>show {isOpen ? 'less' : 'more'}</div>
        <div className="bg-black w-50" style={{height: 1, flexShrink: 1}}></div>
      </div>}
    </div>
  </div>;
}

function InvestmentTiming({ client }) {
  // return <div className="bg-gold ba b--light-gray pa3 f7 lh-copy mb3 br4 flex flex-row items-center justify-center">
  //   <img src={warningIcon} style={{width: 16}} />
  //   <div className="mh3">
  //     The Share App will be retired at the end of September. If you haven't heard from us, please email hi@tryshare.app.
  //   </div>
  // </div>;

  const [account, setAccount] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setAccount(await client.accounts.getMine());
      } catch (e) {
        console.debug(e);
      }
    })();
  }, []);


  if (!account || !account.isFundable) return null;

  if (!account.nextTransferAt) {
    return <Link to="/investments" className="no-underline black">
      <div className="bg-white ba b--light-gray pa3 f7 lh-copy mb3 br4 flex flex-row items-center justify-center">
        <img src={calendarIcon} style={{width: 16}} />
        <div className="mh3">
          You don't have any upcoming transfers. Tap to edit deposit schedule.
        </div>
        <img src={arrowIcon} style={{width: 16}} />
      </div>
    </Link>; 
  }

  const { nextTransferAmount, nextTransferAt } = account;

  return <Link to="/investments" className="no-underline black">
    <div className="bg-white ba b--light-gray pa3 f7 lh-copy mb3 br4 flex flex-row items-center justify-center">
      <img src={calendarIcon} style={{width: 16}} />
      <div className="mh3">
        <span className="bold">Next transfer:</span> {MONEY.formattedDollars(nextTransferAmount / 100, { wholeDollar: true })} on {moment.unix(nextTransferAt).format('MMMM D')}. See details.
      </div>
      <img src={arrowIcon} style={{width: 16}} />
    </div>
  </Link>;
}

function Invitation({ invitation }) {
  return <div className="bg-blue-gray ba br5 b--light-gray" style={{marginBottom: 24}}>
    <div style={{padding: 24}}>
      <div className="tc">
        <Link className="no-underline black" to={`/invitations/${invitation.id}`}>
          <div className="flex justify-center mb2">
            <div className="bg-cyan pa1 ph2 br2 f7">You're invited!</div>
          </div>
          <div className="bold f4">{invitation.group.name}</div>
        </Link>
        <div className="mt3 pt3 bt b--light-gray tl f6">{invitation.group.description}</div>
        <div className="tc mt3">
          <Link to={`/invitations/${invitation.id}`} className="black no-underline">
            <TinyButton>
              <div className="flex flex-row items-center justify-center">
                View <img src={arrowIcon} className="ml2" style={{width: 16}} />
              </div>
            </TinyButton>
          </Link>
        </div>
      </div>
    </div>
  </div>;
}

function Watchlist({ client }) {
  function Quote({ quote, mode, onClick }) {
    const isUp = quote.changePercent >= 0;
    return <div className={`${isUp ? 'bg-cyan' : 'bg-orange'} pa2 f6 mono br3`} onClick={onClick}>
      {mode === 'price' && MONEY.formattedDollars(quote.latestPrice)}
      {mode === 'percentChange' && <>{isUp ? '+' : ''}{(quote.changePercent * 100).toFixed(2)}%</>}
    </div>; 
  }

  const [watchlist, setWatchlist] = useState(null);
  const [watchlistMode, setWatchlistMode] = useState('price');
  const [quotes, setQuotes] = useState({});

  useEffect(() => {
    (async () => {
      setWatchlist(await client.watchlist.get());
    })();
  }, []);

  useEffect(() => {
    if (!watchlist || watchlist.length === 0) return;
    (async () => {
      const tickers = _.map(watchlist, 'company.ticker');
      let response = await client.stocks.get(tickers);
      response = _.mapValues(response, 'quote');
      setQuotes({
        ...quotes,
        ...response,
     });
    })();
  }, [watchlist]);

  if (!watchlist?.length) return null;

  const toggleWatchlistMode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setWatchlistMode(watchlistMode === 'price' ? 'percentChange' : 'price');
  };

  return <div className="mv4 mv5-ns">
    <div className="f4 bold" style={{marginBottom: 24}}>Watchlist</div>
    {watchlist.map((watch) => <Link key={`watch-${watch.id}`} className="black no-underline" to={`/companies/${watch.company.ticker}`}>
      <div className={`flex flex-row items-center justify-between`} style={{paddingBottom: 24}}>
        <div className="w-80 truncate">
          <div className="tl f7 mid-gray lh-copy">{watch.company.ticker}</div>
          <div className="tl f5 bold lh-copy truncate">{watch.company.displayName}</div>
        </div>
        {quotes[watch.company.ticker] && <Quote quote={quotes[watch.company.ticker]} mode={watchlistMode} onClick={toggleWatchlistMode} />}
      </div>
    </Link>)}
    {watchlist.length === 0 && <div className="f6 lh-copy">No watches yet.</div>}
  </div>;
}

function ContributionBadge({ groupUser }) {
  if (!groupUser) return null;

  const { monthlyContribution } = groupUser;
  const isPaused = groupUser.isPaused || monthlyContribution === 0;

  return <div className={`absolute top-0 pv2 ph3 bold bg-white black br5 shadow-2`} style={{fontSize: 13, top: -10, right: -10}}>
    {isPaused ? `Paused` : `${MONEY.formattedDollars(monthlyContribution / 400, { wholeDollar: true })}/wk`}
  </div>;
}